<script setup lang="ts">
const props = defineProps({
  devise: {
    type: Object,
    required: true,
  },
  mode: {
    type: String,
    default: 'glue',
  },
})

const pageSpecials: Ref<AtlantisSpecial[]> = ref([])

const limitedSpecials: ComputedRef<AtlantisSpecial[]> = computed(() => pageSpecials.value.slice(0, 3))

async function requestSpecialsCategories() {
  const categoryId = props.devise.fields.categoryId?.value ?? 35
  const orderby = props.devise.fields.orderby?.value ?? 'priority'

  $voixFetch(`/api/devise/page-specials/all/?category_id=${categoryId}&orderby=${orderby}&`).then((response) => {
    pageSpecials.value = response.data
  })
}

onMounted(() => {
  requestSpecialsCategories()
})
</script>

<template>
  <div class="special-deals px-8 flex flex-col items-center py-20 ">
    <div class="w-full mx-auto text-center pb-12">
      <div
        class="mb-3 break-words"
        :class="{
          'max-w-120 headline': mode === 'glue',
          'text-glueblue-600 font-sans opacity-80 font-black uppercase text-3xl xl:text-[3.75rem] xl:leading-[65px]':
            mode === 'summer',
        }"
      >
        {{ devise.fields.title.text }}
      </div>
      <div
        v-if="devise.fields.description.value"
        class="mb-10 text-center mx-auto"
        :class="{
          'body-lg ': mode === 'glue',
          'text-glueblue-600 opacity-80 text-lg font-sans2 text-normal':
            mode === 'summer',
        }"
      >
        {{ devise.fields.description.value }}
      </div>
      <a
        class="mt-4"
        :class="{
          ' btn-ghost':
            mode === 'glue',
          ' text-glueblue-600 p-3 px-16 border border-glueblue-600':
            mode === 'summer',
        }"
        href="/specials"
      >
        See All Specials
      </a>
    </div>

    <div
      v-if="pageSpecials && pageSpecials.length > 2"
      class="relative clipping-slider overflow-hidden"
    >
      <div class="grid md:grid-cols-2 xl:grid-cols-3 gap-16 container">
        <GlueSpecialsSpecialsLayoutsSmallSpecial
          v-for="special in limitedSpecials"
          :key="special.id"
          class="w-full"
          style="max-width: 359px"
          :mode="mode"
          :special="special"
          :image-on-top="true"
        />
      </div>
    </div>
  </div>
</template>
